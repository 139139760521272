<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="关联的资源类型, 30:预约,40:广告" prop="relationType" >
      </a-form-model-item>
      <a-form-model-item label="关联的资源id, res_site.id或res_advert.id" prop="relationId" >
        <a-input v-model="form.relationId" placeholder="请输入关联的资源id, res_site.id或res_advert.id" />
      </a-form-model-item>
      <a-form-model-item label="订单id" prop="orderId" >
        <a-input v-model="form.orderId" placeholder="请输入订单id" />
      </a-form-model-item>
      <a-form-model-item label="日期" prop="date" >
      </a-form-model-item>
      <a-form-model-item label="时间段" prop="time" >
        <a-input v-model="form.time" placeholder="请输入时间段" />
      </a-form-model-item>
      <a-form-model-item label="是否锁定 1:锁定,0:未锁定" prop="isLock" >
        <a-input v-model="form.isLock" placeholder="请输入是否锁定 1:锁定,0:未锁定" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCalendar, addCalendar, updateCalendar } from '@/api/res/calendar'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        relationType: null,

        relationId: null,

        orderId: null,

        date: null,

        time: null,

        isLock: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        relationType: [
          { required: true, message: '关联的资源类型, 30:预约,40:广告不能为空', trigger: 'change' }
        ],
        relationId: [
          { required: true, message: '关联的资源id, res_site.id或res_advert.id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        relationType: null,
        relationId: null,
        orderId: null,
        date: null,
        time: null,
        isLock: null,
        createTime: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCalendar({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCalendar(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCalendar(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
