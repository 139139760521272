import request from '@/utils/request'


// 查询res-日历列表
export function listCalendar(query) {
  return request({
    url: '/res/calendar/list',
    method: 'get',
    params: query
  })
}

// 查询res-日历分页
export function pageCalendar(query) {
  return request({
    url: '/res/calendar/page',
    method: 'get',
    params: query
  })
}

// 查询res-日历详细
export function getCalendar(data) {
  return request({
    url: '/res/calendar/detail',
    method: 'get',
    params: data
  })
}

// 新增res-日历
export function addCalendar(data) {
  return request({
    url: '/res/calendar/add',
    method: 'post',
    data: data
  })
}

// 修改res-日历
export function updateCalendar(data) {
  return request({
    url: '/res/calendar/edit',
    method: 'post',
    data: data
  })
}

// 删除res-日历
export function delCalendar(data) {
  return request({
    url: '/res/calendar/delete',
    method: 'post',
    data: data
  })
}
